/*custom colors*/
$ah-yellow:      #FFC500;
$ah-red:         #BE1E2D;
$ah-light-blue:  #455D7A;
$ah-dark-blue:   #233142;
$ah-gray:        #EFECEA;

// $jumbotron-bg: $ah-light-blue !global;

$jumbotron-bg: $ah-light-blue;
$headings-color: $ah-gray;
$card-bg: $ah-light-blue;

$theme-colors: (
    "red": $ah-red
);