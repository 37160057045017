.row-top-marg {
  margin-top: 1rem;
}

.background {
  background-color: $ah-dark-blue;
}

.modal-content-heroes{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    pointer-events: auto;
    background-clip: padding-box;
    @include border-radius($border-radius-lg);
    @include box-shadow($modal-content-box-shadow-xs);
    // Remove focus outline from opened modal
    outline: 0;
}



// .bg-image {
//   /* The image used */
//   background-image: url("/assets/images/map-background.jpg");

//   /* Full height */
//   height: 50%;

//   /* Center and scale the image nicely */
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }
